import API from "../API";

import { getTurmasRes, TurmaDTO } from "../Response/GetTurmasRes";

export async function getTurmas(): Promise<getTurmasRes> {
    const response = await API.get("/turmas");
    return response.data;
}

export async function getTodasTurmas(): Promise<TurmaDTO[]> {
    const response = await API.get("/turmas/todas");
    return response.data;
}

export async function getSalas(): Promise<any> {
    const response = await API.get("/turmas/salas");
    return response.data;
}

export async function deleteTurma(id: number) {
    await API.delete(`/turmas/${id}`);
}

export async function updateTurma(turma: TurmaDTO) {
    await API.put(`/turmas/${turma.id}`, turma);
}

export async function createTurma(turma: TurmaDTO) {
    await API.post("/turmas", turma);
}

export async function importarTurmasCSV(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    let response = await API.post("/turmas/import", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    return response;
}

export async function importarSalasCSV(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await API.post("/salas/import", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    return response;
}
