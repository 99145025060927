import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AppProvider } from "../providers/AppContext";

import Home from "../pages/Main";
import SalaDetails from "../pages/SalaDetails";

export default function Router() {
    return (
        <BrowserRouter>
            <AppProvider>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/sala/:sala" element={<SalaDetails/>} />
                    <Route path="*" element={<h1>404</h1>} />
                </Routes>
            </AppProvider>
        </BrowserRouter>
    );
}
