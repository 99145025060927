import styled from "styled-components";
import { SidebarProps } from './interface';

export const Container = styled.div<SidebarProps>`
  width: ${(props) => (props.minimized ? "90px" : "250px")};
  height: 100vh;
  background-color: #1565c0;
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 90px;
  border-bottom: 1px solid #ccc;  
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const ProfileText = styled.div<SidebarProps>`
  display: ${(props) => (props.minimized ? "none" : "block")};
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
`;

export const TextWrapper = styled.div<SidebarProps>`
  display: ${(props) => (props.minimized ? "none" : "block")};
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
`;

export const IconWrapper = styled.div`
  font-size: 24px;
  text-align: center;
  color: #fff;
`;

export const MenuList = styled.ul<SidebarProps>`
  display: ${(props) => (props.minimized ? "flex" : "block")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const MenuHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ccc;
  }
`;

export const MenuTitle = styled.div<SidebarProps>`
  display: ${(props) => (props.minimized ? "none" : "block")};
  padding: 10px 0;
  margin: 10px auto 0;
  text-align: center;
`;  
