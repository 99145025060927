import * as S from './styles';

import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import { useState, FormEvent, useCallback } from 'react';

import { importarTurmasCSV, importarSalasCSV } from '../../services/API/Turmas/TurmasService';
import { makeAdmin } from '../../services/API/Users/UserServices';

export default function Admin() {
    const [showDropzone, setShowDropzone] = useState(false);
    const [selectedType, setSelectedType] = useState('turmas'); // 'turmas' or 'salas'

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        console.log("1")
        try {
            console.log("2")
            const file = acceptedFiles[0];
            console.log("3")
            let status: number = -1; // Initialize with a default value
            console.log("4")
    
            if (selectedType === 'turmas') {
                console.log("5")
                const res = await importarTurmasCSV(file);
                console.log("6")
                status = res.status;
                console.log("7")
                console.log("status", status, res)
            } else if (selectedType === 'salas') {
                console.log("8")
                const res = await importarSalasCSV(file);
                console.log("9")
                status = res.status;
                console.log("10")
                console.log("status", status, res)
            }

            console.log("11")
    
            if (status !== -1) {
                console.log("12")
                exibirModalImport(status);
                console.log("13")
            } else {
                console.log("14")
                // Handle the case when 'status' remains unchanged (error occurred)
                console.log('Error occurred during CSV import.');
                console.log("15")
            }

            console.log("16")
    
        } catch (error) {
            console.log("17")
            console.log("Error importing CSV:", error);
            console.log("18")
        }
    }, [selectedType]);

    function exibirModalImport(status: number) {
        if (status === 200) {
            Swal.fire({
                title: 'CSV importado com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
            });
        } else {
            Swal.fire({
                title: 'Erro ao importar CSV!',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const showDropzoneModal = (type: 'turmas' | 'salas') => {
        setSelectedType(type);
        setShowDropzone(true);
    };

    const closeDropzoneModal = () => {
        setShowDropzone(false);
    };

    const saveItem = (event: FormEvent) => {
        event.preventDefault();
        Swal.fire({
            title: 'Turmas importadas com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
        });
        closeDropzoneModal();
    };

    return (
        <S.Container>
            <S.Title>Admin</S.Title>
            <S.Button onClick={() => showDropzoneModal('turmas')}>Importar turmas</S.Button>
            <S.Button onClick={() => showDropzoneModal('salas')}>Importar salas</S.Button>
            <S.Button onClick={() => showAdminModal('salas')}>Tornar Admin</S.Button>

            {showDropzone && (
                <S.Modal>
                    <S.ModalContent>
                        <S.ModalTitle>Importar CSV</S.ModalTitle>
                        <S.ModalForm onSubmit={saveItem}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Solte o arquivo aqui...</p>
                                ) : (
                                    <p>Arraste e solte um arquivo .csv aqui ou clique para selecionar</p>
                                )}
                            </div>
                            <S.ModalButton onClick={closeDropzoneModal} background="#FF0000">
                                Cancelar
                            </S.ModalButton>
                        </S.ModalForm>
                    </S.ModalContent>
                </S.Modal>
            )}

        </S.Container>
    );

    function showAdminModal(type: 'turmas' | 'salas') {
        Swal.fire({
            title: 'Tornar Admin',
            html: `
                <input id="swal-input1" class="swal2-input" placeholder="Email" type="email">
                <input id="swal-input2" class="swal2-input" placeholder="Admin  Token" type="password">
            `,
            confirmButtonText: 'Tornar Admin',
            preConfirm: async () => {
                const email = (document.getElementById('swal-input1') as HTMLInputElement).value;
                const password = (document.getElementById('swal-input2') as HTMLInputElement).value;
                const response = await makeAdmin({email, password});

                if (response.status === 200) {
                    Swal.fire({
                        title: 'Admin setado com sucesso!',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    });
                } else {
                    Swal.fire({
                        title: 'Erro ao setar Admin!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                }
            }
        });
    }

}
