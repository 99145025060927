import styled from "styled-components";

import { useContext } from "react";
import { AppContext } from "../../providers/AppContext";

import Sigmas from "../Sigmas";
import Sidebar from "../../components/Sidebar";
import Admin from "../Admin";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export default function Home() {
    const { tabSelected, setTabSelected } = useContext(AppContext);

    let content;

    switch (tabSelected) {
        case "dashboard":
            content = <Sigmas/>;
            break;
        case "admin":
            content = <Admin/>;
            break;
        default:
            setTabSelected("dashboard");
            content = <Sigmas/>;
            break;
    }
    
    return (
        <Container>
            <Sidebar/>
            {content}
        </Container>
    );
}
