import API from "../API";

import { AuthenticatedUserReq } from "../Request/AuthenticatedUserReq";
import { AuthenticatedUserRes } from "../Response/AuthenticatedUserRes";

export async function getUserdata(authenticatedUser: AuthenticatedUserReq): Promise<AuthenticatedUserRes> {
    const response = await API.post("/users/userdata", { authenticatedUser });
    return response.data;
}

export async function makeAdmin(payload: Payload){
    const response = await API.post("/users/makeAdmin", payload);
    return response;
}

type Payload = {
    email: string;
    password: string;
};
