const dashSigmas = {
    "segunda": {
        "R.A": [],
        "R.C": [],
        "R.E": [],
        "R.B": [],
        "M.A": [],
        "M.B": [],
        "M.C": [],
        "M.E": []
    },
    "terca": {
        "R.A": [],
        "R.C": [],
        "R.E": [],
        "R.B": [],
        "M.A": [],
        "M.B": [],
        "M.C": [],
        "M.E": []
    },
    "quarta": {
        "R.A": [],
        "R.C": [],
        "R.E": [],
        "R.B": [],
        "M.A": [],
        "M.B": [],
        "M.C": [],
        "M.E": []
    },
    "quinta": {
        "R.A": [],
        "R.C": [],
        "R.E": [],
        "R.B": [],
        "M.A": [],
        "M.B": [],
        "M.C": [],
        "M.E": []
    },
    "sexta": {
        "R.A": [],
        "R.C": [],
        "R.E": [],
        "R.B": [],
        "M.A": [],
        "M.B": [],
        "M.C": [],
        "M.E": []
    }
};

export default dashSigmas;
