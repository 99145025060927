import ReactDOM from 'react-dom';

import GlobalStyles from './styles/GlobalStyles';
import Router from './routes';

function App(){
  return (
    <>
      <GlobalStyles />
      <Router />
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));
