import styled from 'styled-components';

export const TurmaItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: red;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
`;

export const TurmaItemText = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
`;

export const TurmaItemButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
`;


export const TurmaItemButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;
    border: none;
    outline: none;
    &:hover {
        background-color: #000;
        color: #fff;
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Button = styled.button`
  background-color: #4285F4;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const ActionButton = styled.button<{ background?: string }>`
  background-color: ${(props) => props.background || '#4285F4'};
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
  cursor: pointer;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 4px;
`;

export const ModalTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const Input = styled.input`
`;

export const ModalButton = styled.button<{ background?: string }>`
  background-color: ${(props) => props.background || '#4285F4'};
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;
