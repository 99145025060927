import * as S from './styles';

import { useState, useEffect } from 'react';
import { getSalaDetails } from '../../services/API/Salas/SalasService';
import Sidebar from '../../components/Sidebar';


export default function SalaDetails() {
    const [dia, setDia] = useState('segunda');
    const [roomsDia, setRoomsDia] = useState([]);
    const sala = window.location.pathname.split("/")[2];
    const [mock, setMock] = useState<Mock | null>(null);

    const dias = ["segunda", "terca", "quarta", "quinta", "sexta"];

    useEffect(() => {
        async function verifyDay(){
            const day = sessionStorage.getItem("@Sigmas:daySelected");
            if(day === 'Terça') setDia("terca");
            else if (day) setDia(day.toLowerCase());
            else setDia("segunda");
        }
        async function getDetails(){
            const response = await getSalaDetails(sala);
            setMock(response);
        }
        getDetails();
        verifyDay();
    }, [sala]);

    if(!mock) return <h1>Carregando...</h1>;

    return (
        <>
        <Sidebar/>
        
        <S.Container>
            <S.Header>
                <S.NameRoom>{mock.infoSala[0].sala}</S.NameRoom>
            </S.Header>
            <S.Tags>
                <S.Tag>CAPACIDADE: {mock.infoSala[0].capacidade}</S.Tag>
                {mock.infoSala[0].ar_condicionado > 0 && <S.Tag>AR CONDICIONADO</S.Tag>}
                {mock.infoSala[0].projetor > 0 && <S.Tag>PROJETOR</S.Tag>}
                {mock.infoSala[0].ventilador > 0 && <S.Tag>VENTILADOR</S.Tag>}
                {mock.infoSala[0].tela > 0 && <S.Tag>TELA</S.Tag>}
                {mock.infoSala[0].cursos_alocados && <S.Tag>CURSOS ALOCADOS: {mock.infoSala[0].cursos_alocados}</S.Tag>}
            </S.Tags>

            <S.Day>
                {dias.map((today, index) => (
                    <S.DayButton 
                        key={index}
                        onSelectEvent={dia === today}
                        onClick={() => setDia(today)}
                    >
                        {capitalize(today)}
                    </S.DayButton>
                ))}
            </S.Day>

            {mock[dia]?.map(room => {
                return (
                    <S.Card>
                        <S.CardHeader>
                            <S.CardSubtitle>{room.alunos} alunos | {room.horario}</S.CardSubtitle>
                            <S.CardTitle vazio={false}>{room.disciplina}</S.CardTitle>
                        </S.CardHeader>
                        <S.CardBody>
                            <S.CardSubtitle>{room.professor}</S.CardSubtitle>
                        </S.CardBody>
                    </S.Card>
                )
            })}

            {mock[dia]?.length === 0 && (
                <S.Card>
                    <S.CardHeader>
                        <S.CardTitle vazio={true}>Nenhuma aula cadastrada</S.CardTitle>
                    </S.CardHeader>
                </S.Card>
            )}
        </S.Container>
        </>
    );

    function capitalize(str: string) {
        if(str === "terca") return "Terça";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    type Room = {
        idTurma: number;
        disciplina: string;
        professor: string;
        alunos: number;
        horario: string;
        sala: string;
    }

    type InfoSala = {
        id: number;
        sala: string;
        bloco: string;
        capacidade: number;
        ar_condicionado: number;
        projetor: number;
        ventilador: number;
        tela: number;
        cursos_alocados: string;
        idTurma?: number;
        disciplina?: string;
        professor?: string;
        alunos?: number;
        horario?: string;
    }

    type Mock = {
        infoSala: InfoSala[];
        segunda: Room[];
        terca: Room[];
        quarta: Room[];
        quinta: Room[];
        sexta: Room[];
        [key: string]: InfoSala[] | Room[];
    }
}