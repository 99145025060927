import Swal from 'sweetalert2';
import { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import { AppContext } from '../../providers/AppContext';

import { SiGoogleclassroom } from 'react-icons/si';
import { HiOutlineLogout } from 'react-icons/hi';
import { FiSettings } from "react-icons/fi";
import { BsDoorClosed } from "react-icons/bs";

import defaultProfile from '../../assets/defaultProfile.jpg';
import { AuthenticatedUserRes } from '../../services/API/Response/AuthenticatedUserRes';

export default function Sidebar() {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const { signed, user, userdata, signInGoogle, signOut, setTabSelected, setCourseSelected } = useContext(AppContext);

    const profileName = getName(user?.displayName ? user?.displayName : "");
    const profilePhoto = user?.photoURL ? user?.photoURL : defaultProfile;

    const handleMouseLeave = () => {
        setMinimized(true);
    };

    const handleMouseEnter = () => {
        setMinimized(false);
    };

    return (
        <S.Container minimized={minimized} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <S.Profile>
                <S.ProfileImage src={profilePhoto} alt="Profile Image" />
                {signed ?
                    <S.ProfileText minimized={minimized}>
                        Olá, {profileName}
                    </S.ProfileText>
                    : goLoginButton()
                }
            </S.Profile>

            <S.MenuList minimized={minimized}>
                {MenuListComponent(<SiGoogleclassroom />, "Dashboard", openDash)}
                {/*ADSON PEDIU PRA REMOVER MENU DA SALA PQ TAVA CONFUNDINDO E N FAZ NADA*/}
                {/* {MenuListComponent(<BsDoorClosed />, "Sala", openSala)} */}
                {/*TODO: as letras tão sem delay ao evento de mouse em cima do elemento*/}
                { isAdmin(userdata) && MenuListComponent(<FiSettings />, "Admin")}
                { signed && MenuListComponent(<HiOutlineLogout />, "Logout", handleLogout)}
            </S.MenuList>
        </S.Container>
    );

    function MenuListComponent(icon: any, text: string, callback?: any) {
        return (
            <S.MenuItem onClick={() => {
                if (callback) callback(); 
                setTabSelected(text.split(" ")[0].toLowerCase());
                setCourseSelected(-1);
            }}
                >
                <S.IconWrapper> {icon} </S.IconWrapper>
                {!minimized && (<S.TextWrapper minimized={minimized} selected={selected}> {text} </S.TextWrapper>)}
            </S.MenuItem>
        );
    }

    function getName(name: string) {
        const nameArray = name.split(" ");
        const firstName = nameArray[0];
        const lastName = nameArray[nameArray.length - 1];
        return firstName + " " + lastName;
    }

    function goLoginButton() {
        return (
            <S.ProfileText minimized={minimized} onClick={handleLoginFromGoogle}>
                Fazer login
            </S.ProfileText>
        );
    }

    async function handleLoginFromGoogle() {
        await signInGoogle();
    }

    function handleLogout() {
        Swal.fire({
            title: 'Tem certeza que deseja sair?',
            showCancelButton: true,
            confirmButtonText: `Sair`,
            cancelButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                signOut();
            }
        });
    }

    function isAdmin(userdata: AuthenticatedUserRes | null) {
        return userdata?.role === "ADMIN";
    }

    // Adson pediu pra tirar, se no futuro quiser colocar de volta, rever a lógica e dá uma funcionalidade pra ela
    function openSala() {
        // checar se na url tem o /sala
        if (window.location.pathname.includes("/sala")){
            Swal.fire({
                title: 'Sala',
                text: 'Ops! Você já está no menu de salas!',
                icon: 'info',
                confirmButtonText: 'OK'
            });
            return;
        } else {
            Swal.fire({
                title: 'Sala',
                text: 'Ops! Para abrir essa aba selecione uma sala!',
                icon: 'info',
                confirmButtonText: 'OK'
            });
            return;
        }
    }

    function openDash() {
        navigate("/");
    }
}
