import { useState, createContext, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { app } from "../services/firebaseConfig";
import { AppContextProps, AppProviderProps, User } from "./interfaces";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getUserdata } from "../services/API/Users/UserServices";
import { AuthenticatedUserRes } from "../services/API/Response/AuthenticatedUserRes";
import { AuthenticatedUserReq } from "../services/API/Request/AuthenticatedUserReq";

const provider = new GoogleAuthProvider();

export const AppContext = createContext<AppContextProps>({
    user: null,
    userdata: null,
    signed: false,
    signInGoogle: () => { },
    signOut: () => { },
    tabSelected: "",
    setTabSelected: () => { },
    courseSelected: -1,
    setCourseSelected: () => { }
});

async function getUserdetails(userTeste: User | null) {
    const autheticatedUser: AuthenticatedUserReq = {
        uid: userTeste?.uid ? userTeste.uid : "",
        email: userTeste?.email ? userTeste.email : "",
    }
    const user = await getUserdata(autheticatedUser);
    return user;
}

export const AppProvider = ({ children }: AppProviderProps) => {
    const [ user, setUser ] = useState<User | null>(null);
    const [ userdata, setUserdata ] = useState<AuthenticatedUserRes | null>(null);
    const [ tabSelected, setTabSelected ] = useState<string>("dashboard");
    const [ courseSelected, setCourseSelected ] = useState<number>(-1);
    const auth = getAuth(app);

    useEffect(() => {
        const loadStorageData = async () => {
            const storageUser = sessionStorage.getItem("@AuthFirebase:user");
            const storageToken = sessionStorage.getItem("@AuthFirebase:token");
            
            if (storageToken && storageUser) {
                const userdata = await getUserdetails(JSON.parse(storageUser));
                setUser(JSON.parse(storageUser));
                //TODO: Se o userdata tiver a prop desativado true, não logar
                setUserdata(userdata);
            }
        };
        loadStorageData();
    }, []);

    function signInGoogle() {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                const user = result.user;
                const userTeste: User = user as User;
                setUser(userTeste);
                const userdata = await getUserdetails(userTeste);
                //TODO: Se o userdata tiver a prop desativado true, não logar
                setUserdata(userdata);
                sessionStorage.setItem("@AuthFirebase:token", token ? token : "");
                sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    function signOut() {
        sessionStorage.clear();
        setUser(null);
        setUserdata(null);
        return <Navigate to="/" />;
    }

    return (
        <AppContext.Provider value={{
            signed: !!user,
            user,
            userdata,
            signInGoogle,
            signOut,
            tabSelected, 
            setTabSelected,
            courseSelected,
            setCourseSelected
        }}>
            {children}
        </AppContext.Provider>
    );
};
