import * as S from './styles';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BsSnow, BsWind } from "react-icons/bs";
import { FcVideoProjector } from "react-icons/fc";

import { getSalas } from '../../services/API/Turmas/TurmasService';

import MockRooms from "../../services/mock/dashSigmas"

import {
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";

import {
    ExpandMore as ExpandMoreIcon,
    ArrowBackIos as ArrowBackIosIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";

import { Button } from "@mui/material";

export default function Sigmas() {
    const navigate = useNavigate();
    const [indexDay, setIndexDay] = useState(0);
    const [rooms, setRooms] = useState(MockRooms);
    const [roomsDay, setRoomsDay] = useState(rooms.segunda);
    const dias = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];

    useEffect(() => {
        async function getRooms() {
            const rooms = await getSalas();
            setRooms(rooms);
            setRoomsDay(rooms.segunda);
        }
        getRooms();
        sessionStorage.setItem("@Sigmas:daySelected", dias[indexDay]);
    }, []);

    return (
        <S.Container>
            <SelectDay />
            {/*TODO: Fazer um loading para não precisar usar o mock enquanto n chega response */}
            <S.RoomsContainer>
            {Object.entries(roomsDay).map(([roomKey, roomData]) => (
        RenderRooms(roomKey, roomData)
      ))}
            </S.RoomsContainer>
        </S.Container>
    );

    function RenderRooms(block: string, salas: Sala[]){
        return (
            <Accordion key={block} className="accordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography id="name-block">{block}</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                    <Typography className="rooms">
                        {salas.map(({ sala, qtAlunos, capacidade, ar_condicionado, ventilador, projetor }) => (
                            <Button onClick={() => openRoom(sala)} variant="contained">
                                <S.Capacity>{capacidade}</S.Capacity>
                                <S.Icons>
                                    {ventilador === true && <BsWind />}
                                    {ar_condicionado === true && <BsSnow />}
                                    {projetor === true && <FcVideoProjector />}
                                </S.Icons>
                                <S.RoomNumber>{sala}</S.RoomNumber>
                                {/*TODO: Quando por o mouse no indicator aparecer nome da disciplina */}
                                <S.IndicatorGroup>
                                    {qtAlunos?.map((student, index) => (
                                        <S.Indicator key={index} students={student} capacity={capacidade}>
                                            {student}
                                        </S.Indicator>
                                    ))}
                                </S.IndicatorGroup>
                            </Button>
                        ))}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        )
    }

    function openRoom(sala: string) {
        navigate(`/sala/${sala}`);
    }

    function SelectDay() {
        return (
            <S.Day>
                <span onClick={() => decrementDay()}> <ArrowBackIosIcon /> </span>
                <Typography id="text">{dias[indexDay]}</Typography>
                <span onClick={() => incrementDay()}> <ArrowForwardIosIcon /> </span>
            </S.Day>
        );
    }

    function incrementDay() {
        switch (indexDay) {
            case 0:
                setIndexDay(1);
                setRoomsDay(rooms.terca);
                break;
            case 1:
                setIndexDay(2);
                setRoomsDay(rooms.quarta);
                break;
            case 2:
                setIndexDay(3);
                setRoomsDay(rooms.quinta);
                break;
            case 3:
                setIndexDay(4);
                setRoomsDay(rooms.sexta);
                break;
            case 4:
                setIndexDay(0);
                setRoomsDay(rooms.segunda);
                break;
            default:
                break;
        }
        saveDaySelected("INCREMENT");
    }

    function decrementDay() {
        switch (indexDay) {
            case 0:
                setIndexDay(4);
                setRoomsDay(rooms.sexta);
                break;
            case 1:
                setIndexDay(0);
                setRoomsDay(rooms.segunda);
                break;
            case 2:
                setIndexDay(1);
                setRoomsDay(rooms.terca);
                break;
            case 3:
                setIndexDay(2);
                setRoomsDay(rooms.quarta);
                break;
            case 4:
                setIndexDay(3);
                setRoomsDay(rooms.quinta);
                break;
            default:
                break;
        }
        saveDaySelected("DECREMENT");
    }

    function saveDaySelected(operation: string) {
        if (operation === "INCREMENT") {
        sessionStorage.setItem("@Sigmas:daySelected", dias[indexDay+1]);
        } else {
        sessionStorage.setItem("@Sigmas:daySelected", dias[indexDay-1]);
        }
    }

    type Sala = {
        sala: string;
        capacidade: number;
        qtAlunos: number[];
        ar_condicionado: boolean;
        projetor: boolean;
        ventilador: boolean;
    };
}
