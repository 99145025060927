import API from "../API";

export async function getSalaDetails(sala: string): Promise<any> {
    const response = await API.get(`/turmas/sala/${sala}`);
    return response.data;
}

export async function importarTurmasCSV(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    await API.post("/turmas/import", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}
