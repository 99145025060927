import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDYX4JU_FerYN8RdksulsIFDHTklaLTnxU",
  authDomain: "ccae-manager.firebaseapp.com",
  projectId: "ccae-manager",
  storageBucket: "ccae-manager.appspot.com",
  messagingSenderId: "625971000348",
  appId: "1:625971000348:web:6123a993dad8fa31fadbd9",
  measurementId: "G-DL8S5F3TH8"
};

export const app = initializeApp(firebaseConfig);
